export let initial = {
    Projects: [
    ],
    isRun: false
}

export const request_host = async (IRequestHost) => {
    let raw = "";
    if (IRequestHost.item !== undefined) {
        raw = JSON.stringify(IRequestHost.item);
    }
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Accept-Language", "ru-RU,ru;q=0.8,en-US;q=0.5,en;q=0.3");
    myHeaders.append("Alt-Used", "project.arduino-it.ru");
    myHeaders.append("Sec-Fetch-Dest", "empty");
    myHeaders.append("Sec-Fetch-Mode", "cors");
    myHeaders.append("Sec-Fetch-Site", "same-origin");
    myHeaders.append("H0st", "project.it-arduin0.ru");
    let response = await fetch(`https://project.arduino-it.ru/api/${IRequestHost.url}${IRequestHost.otherData}`, {
        method: IRequestHost.item !== undefined ? 'POST' : "GET",
        headers: myHeaders,
        body: IRequestHost.item !== undefined ? raw : undefined,
    })
    return await response.json();
}


export async function projects (data) {
    if (!initial.isRun) {
        let response = await request_host({ url: "projects", item: undefined, otherData: data })
        console.log(response.status, response.data)
        if (response.status === "success") {
            initial.Projects = response.data
        }
        initial.isRun = true;
        return response.data;
    }
    return initial.Projects
}

export async function project (id) {
    let response = await request_host({ url: `projects/${id}`, item: undefined, otherData: "" })
    // console.log(response.status, response.data)
    console.log(response)
    if (response.status === "success") {
        return {
            h1: response.data.name_project,
            allInfo: {
                images_id: response.data.images_id,
                whoDid: response.data.whoDid,
                desc: response.data.desc,
                modules: response.data.modules,
                codeUrl: response.data.codeUrl,
                video: response.data.video,
                tinkercad: response.data.tinkercad
            }
        };
    }
    return false;
}