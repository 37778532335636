import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import moduleCSS from "./App.module.css";
import Info from "./InfoAboutThisProject/Info";
import MainContainer from "./Main/MainContainer";
import Platform from "./Platform/Platform";
import Projects from "./Projects/Projects";
import NotFound from "./NotFound/Page404";
import Instruction from "./Instuction/Instruction";
import { useEffect, useState } from "react";

let MRouters = (props) => {
  return (
    <a className={moduleCSS.RouterClick} href={props.url}>
      {props.name}
    </a>
  );
};
const WidthSizer = (flagActive, myWidth) => {
  myWidth >= 1080 ? flagActive(true) : flagActive();
};
const App = (props) => {
  let MapRouters = props.store.MainData.RouterBtns.map((m) => (
    <MRouters url={m.url} name={m.name} flag={m.flag} />
  ));
  let myWidth = window.innerWidth;
  const [flag, flagActive] = useState(0);
  useEffect(() => {
    WidthSizer(flagActive, myWidth);
  }, []);

  return (
    <BrowserRouter>
      <div className="body">
        <div className="body_header" id="b1">
          <div
            className="body_header_border_img"
            width="40px"
            height="40px"
          ></div>
          <div onClick={() => flagActive(true)} className="body_header_border">
            <svg
              className="body_header_border_logo"
              xmlns="http://www.w3.org/2000/svg"
              width="57"
              height="55"
              viewBox="0 0 57 55"
              fill="none"
            >
              <rect
                x="10.8795"
                y="10.5"
                width="35.5179"
                height="34"
                rx="2.19048"
                fill="#C6B8F8"
              />
              <path
                d="M17.222 18.7619C17.222 17.5522 18.2028 16.5715 19.4125 16.5715H37.8645C39.0743 16.5715 40.055 17.5522 40.055 18.7619V36.2381C40.055 37.4479 39.0743 38.4286 37.8645 38.4286H19.4125C18.2028 38.4286 17.222 37.4479 17.222 36.2381V18.7619Z"
                fill="#242230"
              />
              <path
                d="M18.4905 19.9762C18.4905 18.7664 19.4712 17.7857 20.681 17.7857H36.5959C37.8057 17.7857 38.7864 18.7664 38.7864 19.9762V35.0238C38.7864 36.2336 37.8057 37.2143 36.5959 37.2143H20.681C19.4712 37.2143 18.4905 36.2336 18.4905 35.0238V19.9762Z"
                fill="#9C8FC8"
              />
              <path
                d="M2 34.9048C1.39512 34.9048 0.904762 35.3951 0.904762 36C0.904762 36.6049 1.39512 37.0952 2 37.0952V34.9048ZM12.148 37.0952H13.2432V34.9048H12.148V37.0952ZM2 37.0952H12.148V34.9048H2V37.0952Z"
                fill="#9C8FC8"
              />
              <path
                d="M2 26.4048C1.39512 26.4048 0.904762 26.8951 0.904762 27.5C0.904762 28.1049 1.39512 28.5952 2 28.5952V26.4048ZM12.148 28.5952H13.2432V26.4048H12.148V28.5952ZM2 28.5952H12.148V26.4048H2V28.5952Z"
                fill="#9C8FC8"
              />
              <path
                d="M2 17.9047C1.39512 17.9047 0.904762 18.3951 0.904762 19C0.904762 19.6049 1.39512 20.0952 2 20.0952V17.9047ZM12.148 20.0952H13.2432V17.9047H12.148V20.0952ZM2 20.0952H12.148V17.9047H2V20.0952Z"
                fill="#9C8FC8"
              />
              <path
                d="M45.1289 34.9048H44.0337V37.0952H45.1289V34.9048ZM55.2769 37.0952C55.8818 37.0952 56.3721 36.6049 56.3721 36C56.3721 35.3951 55.8818 34.9048 55.2769 34.9048V37.0952ZM45.1289 37.0952H55.2769V34.9048H45.1289V37.0952Z"
                fill="#9C8FC8"
              />
              <path
                d="M45.1289 26.4048H44.0337V28.5952H45.1289V26.4048ZM55.2769 28.5952C55.8818 28.5952 56.3721 28.1049 56.3721 27.5C56.3721 26.8951 55.8818 26.4048 55.2769 26.4048V28.5952ZM45.1289 28.5952H55.2769V26.4048H45.1289V28.5952Z"
                fill="#9C8FC8"
              />
              <path
                d="M45.1289 17.9047H44.0337V20.0952H45.1289V17.9047ZM55.2769 20.0952C55.8818 20.0952 56.3721 19.6049 56.3721 19C56.3721 18.3951 55.8818 17.9047 55.2769 17.9047V20.0952ZM45.1289 20.0952H55.2769V17.9047H45.1289V20.0952Z"
                fill="#9C8FC8"
              />
              <path
                d="M20.8541 43.2857V42.1905H18.6637V43.2857H20.8541ZM18.6637 53C18.6637 53.6049 19.154 54.0952 19.7589 54.0952C20.3638 54.0952 20.8541 53.6049 20.8541 53H18.6637ZM18.6637 43.2857V53H20.8541V43.2857H18.6637Z"
                fill="#9C8FC8"
              />
              <path
                d="M29.7337 43.2857V42.1905H27.5432V43.2857H29.7337ZM27.5432 53C27.5432 53.6049 28.0335 54.0952 28.6384 54.0952C29.2433 54.0952 29.7337 53.6049 29.7337 53H27.5432ZM27.5432 43.2857V53H29.7337V43.2857H27.5432Z"
                fill="#9C8FC8"
              />
              <path
                d="M38.6132 43.2857V42.1905H36.4227V43.2857H38.6132ZM36.4227 53C36.4227 53.6049 36.9131 54.0952 37.5179 54.0952C38.1228 54.0952 38.6132 53.6049 38.6132 53H36.4227ZM36.4227 43.2857V53H38.6132V43.2857H36.4227Z"
                fill="#9C8FC8"
              />
              <path
                d="M20.8541 2C20.8541 1.39512 20.3638 0.904762 19.7589 0.904762C19.154 0.904762 18.6637 1.39512 18.6637 2H20.8541ZM18.6637 11.7143V12.8095H20.8541V11.7143H18.6637ZM18.6637 2V11.7143H20.8541V2H18.6637Z"
                fill="#9C8FC8"
              />
              <path
                d="M29.7337 2C29.7337 1.39512 29.2433 0.904762 28.6384 0.904762C28.0335 0.904762 27.5432 1.39512 27.5432 2H29.7337ZM27.5432 11.7143V12.8095H29.7337V11.7143H27.5432ZM27.5432 2V11.7143H29.7337V2H27.5432Z"
                fill="#9C8FC8"
              />
              <path
                d="M38.6132 2C38.6132 1.39512 38.1228 0.904762 37.5179 0.904762C36.9131 0.904762 36.4227 1.39512 36.4227 2H38.6132ZM36.4227 11.7143V12.8095H38.6132V11.7143H36.4227ZM36.4227 2V11.7143H38.6132V2H36.4227Z"
                fill="#9C8FC8"
              />
            </svg>
            <p className="body_header_border_line"></p>
            <a href="#" className="body_header_border_name">
              ENGINEER
            </a>
          </div>

          {flag ? (
            <div className="body_header_router">
              <div>{MapRouters}</div>
              {myWidth >= 1080 ? (
                ''
              ) : (
                <a
                  href="#"
                  onClick={() => flagActive(false)}
                  className={moduleCSS.RouterClick}
                >
                  Выход
                </a>
              )}
            </div>
          ) : (
            <div onClick={() => flagActive(true)} className="burger-menu">
              Меню
            </div>
          )}
        </div>

        <Routes>
          <Route path="/" element={<MainContainer />} />,
          <Route path="/Projects" element={<Projects store={props.store} />} />
          <Route path="/Platform" element={<Platform />} />
          <Route path="/Info/:id" element={<Info />} />
          <Route path="*" element={<NotFound />} status={404} />
          <Route path="/Instruction" element={<Instruction />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
